<template>
  <vca-card>
    <h2>{{ $t("network.artists.list.header") }}</h2>
    <vca-card class="shadowed vca-center">
      <ArtistTable />
      <vca-popup
        v-if="current != null"
        :show="current != null"
        :title="$t('network.artists.popup.edit', { 0: current.name })"
        @close="current = null"
      >
        <ArtistsEdit />
      </vca-popup>
    </vca-card>
  </vca-card>
</template>

<script>
import ArtistTable from "@/components/network/artists/ArtistTable";
import ArtistsEdit from "@/components/network/artists/ArtistEdit";
export default {
  name: "NetworkList",
  components: { ArtistTable, ArtistsEdit },
  created() {
    this.$store.dispatch({ type: "artists/list" });
  },
  computed: {
    current: {
      get() {
        return this.$store.state.artists.current;
      },
      set(value) {
        this.$store.commit("artists/current", value);
      },
    },
  },
};
</script>
