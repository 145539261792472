<template>
  <div>
    <ArtistForm v-model="current" @submit="update" />
    <div class="vca-center">
      <vca-cancel-button
        @click="deleteArtist()"
        :placeholder="$t('button.delete')"
      />
    </div>
  </div>
</template>

<script>
import ArtistForm from "@/components/network/artists/ArtistForm";
export default {
  name: "ArtistEdit",
  components: { ArtistForm },
  computed: {
    current: {
      get() {
        return this.$store.state.artists.current;
      },
      set(value) {
        this.$store.commit("artists/current", value);
      },
    },
  },
  methods: {
    update() {
      if (!confirm(this.$t("network.artists.messages.update"))) {
        return false;
      }
      this.$store.dispatch({ type: "artists/update" });
    },
    deleteArtist() {
      if (
        confirm(
          this.$t("network.artists.edit.delete.confirm", {
            0: this.current.name,
          })
        )
      ) {
        this.$store.dispatch({
          type: "artists/delete",
          data: this.current.id,
        });
      }
    },
  },
};
</script>
<style lang="scss">
.add-city {
  button {
    margin: 5px 0 10px 10px;
  }
}
</style>
