<template>
  <div class="table-container">
    <VcaTable
      store="artists"
      :list="getList()"
      :colspan="3"
      :searchLabel="$t('network.artists.list.search')"
    >
      <template v-slot:colspan>
        <col width="1%" />
        <col width="58%" />
        <col width="1%" />
      </template>

      <template v-if="selected.length > 0" v-slot:desktop-options>
        <vca-cancel-button
          class="vca-left table-header-option"
          @click="deleteSelection()"
          :placeholder="$t('button.delete')"
        />
      </template>

      <template v-slot:desktop-header>
        <th class="vca-table-check">
          <label><vca-checkbox v-model="all_selected" /></label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('name')">
          <label> {{ $t("network.artists.name.label") }} &varr;</label>
        </th>
        <th class="vca-table-cell">
          <label>{{ $t("table.options") }}</label>
        </th>
      </template>

      <template v-slot:desktop-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in getList()"
          :key="index"
        >
          <td class="vca-table-check">
            <label><vca-checkbox v-model="selected" :id="res.id" /></label>
          </td>
          <td class="vca-table-cell">
            <label> {{ res.name }} </label>
          </td>
          <td class="vca-table-cell" @click="setCurrent(res)">
            <label
              ><img
                class="editable"
                src="@/assets/icons/edit.png"
                :title="$t('button.edit')"
                :alt="$t('button.edit')"
            /></label>
          </td>
        </tr>
      </template>

      <template v-slot:mobile-header>
        <div><img width="20px" src="~@/assets/icons/sort.png" /></div>
        <div class="sortable" @click="sort('name')">
          <label>{{ $t("network.artists.name.label") }} &varr;</label>
        </div>
      </template>

      <template v-slot:mobile-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in getList()"
          :key="index"
        >
          <td class="vca-table-cell">
            <vca-column>
              <h3>{{ res.name }}</h3>
            </vca-column>
          </td>
        </tr>
      </template>
    </VcaTable>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import VcaTable from "@/components/utils/VcaTable";
export default {
  name: "NetworkArtistsTable",
  components: { VcaTable },
  data() {
    return {
      all_selected: false,
      selected: [],
      store: "artists/pg",
      initial: true,
      currentFilter: {},
      currentTextFilter: "",
    };
  },
  watch: {
    pg: {
      handler(val) {
        this.$store.commit(this.store + "/pagination", val);
      },
      deep: true,
    },
    all_selected: function (nVal) {
      this.selected = nVal ? this.getList().map((el) => el.id) : [];
    },
  },
  created() {
    this.filter = {};
  },
  computed: {
    filter: {
      set(value) {
        this.pg.filterObject = value;
      },
      get() {
        return this.pg.filterObject;
      },
    },
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
    ...mapGetters({
      list: "artists/list",
    }),
    emptyFilter() {
      return !this.pg.filter || this.pg.filter == "";
    },
    sameFilter() {
      return (
        Object.entries(this.currentFilter).toString() ===
          Object.entries(this.filter).toString() &&
        this.currentTextFilter == this.pg.filter
      );
    },
  },
  methods: {
    setCurrent(value) {
      if (this.current && this.current.id == value.id) {
        this.$store.commit("artists/current", null);
      } else {
        this.$store.commit("artists/current", { ...value });
      }
    },
    deleteSelection() {
      if (confirm(this.$t("network.artists.edit.delete_selected.confirm"))) {
        this.$store.commit("suppressMsg", true);
        Promise.all(
          this.selected.map((el) =>
            this.$store.dispatch({
              type: "artists/delete",
              data: el,
            })
          )
        )
          .then(() => {
            this.notification({
              title: "messages.title.success",
              body: this.$t("messages.artist.selection_deleted"),
              type: "success",
            });
          })
          .catch(() => {
            this.notification({
              title: "messages.title.error",
              body: this.$t("messages.artist.not_deleted"),
              type: "error",
            });
          })
          .finally(() => {
            this.$store.commit("suppressMsg", false);
          });
      }
    },
    getList() {
      // If the list is empty, we have an empty array
      if (!this.list) {
        return [];
      }

      // If the current page is out of scope, set it to last page
      if (this.pg.currentPage > this.pg.pageCount) {
        this.$store.commit(this.store + "/prevPage");
      }

      // Create a list for filtering and start filtering
      var filteredList = this.list;

      filteredList = filteredList.filter((row, index) => {
        // Add a rank to the current entry
        row.rank = index + 1;

        // If the filter is empty, everything is fine
        if (this.emptyFilter) {
          return true;
        }

        // Filter for the current value of row.team_name
        return (
          row.name.toLowerCase().indexOf(this.pg.filter.toLowerCase()) > -1
        );
      });

      // Set the length of the list to the current filtered list
      this.pg.listLength = filteredList.length == 0 ? 1 : filteredList.length;
      if (
        this.pg.listLength < this.pg.pageSize ||
        this.pg.pageSize > filteredList.length
      ) {
        // If the length is smaller than the current page size, we adjust the pagesize to the length of the filtered list
        this.pg.pageSize = this.pg.listLength;
      } else if (!this.sameFilter || this.initial) {
        // If the filter has changed, we resize the list
        this.initial = false;
        this.pg.pageSize =
          this.pg.currentPageSize > this.pg.listLength
            ? this.pg.listLength
            : this.pg.currentPageSize;
      }
      // Set the new filters
      if (!this.sameFilter) {
        this.currentTextFilter = this.pg.filter;
        this.currentFilter = JSON.parse(JSON.stringify(this.filter));
      }

      // Now we filter the list to the current pageSize and paginate it
      return filteredList.filter((row, index) => {
        let start = (this.pg.currentPage - 1) * this.pg.pageSize;
        let end = this.pg.currentPage * this.pg.pageSize;
        if (index >= start && index < end) return true;
      });
    },
    sort(col) {
      // When sorting, we set the current page to 1
      this.$store.commit(this.store + "/firstPage");

      // If we have an empty list, return an empty array
      if (!this.list) {
        return [];
      }

      // Define the sorting direction, if current sort is the same as the column to sort, then switch direction
      if (this.pg.currentSort === col) {
        this.pg.currentSortDir =
          this.pg.currentSortDir === "asc" ? "desc" : "asc";
      } else {
        this.pg.currentSort = col;
      }

      // Sort the list depending on the column and the direction
      this.list.sort((a, b) => {
        var final_key = this.pg.currentSort;

        // Sort nested objects if sorting column contains a dot (.)
        if (col.indexOf(".") !== -1) {
          var column = col.split(".");
          var len = column.length;
          var i = 0;
          while (i < len - 1) {
            a = a[column[i]];
            b = b[column[i]];
            i++;
            final_key = column[i];
          }
        }

        // Set zero 0 at the end
        // if (parseInt(a[final_key]) == 0) return 1;
        // if (parseInt(b[final_key]) == 0) return -1;

        // Comapre integer values
        if (
          a[final_key] === parseInt(a[final_key], 10) ||
          a[final_key] === parseFloat(a[final_key], 10)
        ) {
          return this.pg.currentSortDir === "desc"
            ? a[final_key] - b[final_key]
            : b[final_key] - a[final_key];
        }

        // Compare string values
        let modifier = 1;
        if (this.pg.currentSortDir === "desc") modifier = -1;
        if (a[final_key].toLowerCase() < b[final_key].toLowerCase())
          return -1 * modifier;
        if (a[final_key].toLowerCase() > b[final_key].toLowerCase())
          return 1 * modifier;
        return 0;
      });
    },
  },
};
</script>
